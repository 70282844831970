$primary: #3b71ca;
$secondary: #9fa6b2;
$success: #33cc66;
$danger: #cc3333;
$info: #33ccee;
$body-color: #333;
$btn-secondary-bg: $secondary;
$btn-secondary-text: $primary;
$breadcrumb-divider: quote("|");
$nav-pills-link-padding-x: 1rem;
$nav-pills-link-font-size: .825rem;
$nav-pills-link-active-color: $primary;
$nav-pills-link-active-bg: rgba($primary, 0.1);
$nav-pills-link-hover-bg: none;
$nav-pills-margin: 0;
$nav-pills-link-border-radius: 0;
$accordion-button-padding-x: 1rem;

// mdb
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/functions';
@import '~mdb-react-ui-kit/dist/scss/free/functions';
@import '~mdb-react-ui-kit/dist/scss/free/variables';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/variables';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/maps';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/mixins';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/utilities';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/root';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/reboot';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/type';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/images';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/containers';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/grid';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/tables';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/forms';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/buttons';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/transitions';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/dropdown';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/button-group';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/nav';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/navbar';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/card';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/breadcrumb';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/pagination';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/badge';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/alert';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/accordion';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/progress';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/placeholders';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/list-group';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/close';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/toasts';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/modal';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/popover';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/carousel';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/spinners';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/offcanvas';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/tooltip';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/helpers';
@import '~mdb-react-ui-kit/dist/scss/free/utilities';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/utilities/api';
@import '~mdb-react-ui-kit/dist/scss/free/mixins';
@import '~mdb-react-ui-kit/dist/scss/free/utilities';
@import '~mdb-react-ui-kit/dist/scss/free/root';
@import '~mdb-react-ui-kit/dist/scss/free/reboot';
@import '~mdb-react-ui-kit/dist/scss/free/type';
@import '~mdb-react-ui-kit/dist/scss/free/colors';
@import '~mdb-react-ui-kit/dist/scss/free/shadows';
@import '~mdb-react-ui-kit/dist/scss/free/flag';
@import '~mdb-react-ui-kit/dist/scss/free/images';
@import '~mdb-react-ui-kit/dist/scss/free/forms/form-control';
@import '~mdb-react-ui-kit/dist/scss/free/forms/form-select';
@import '~mdb-react-ui-kit/dist/scss/free/forms/form-check';
@import '~mdb-react-ui-kit/dist/scss/free/forms/form-file';
@import '~mdb-react-ui-kit/dist/scss/free/forms/input-group';
@import '~mdb-react-ui-kit/dist/scss/free/forms/validation';
@import '~mdb-react-ui-kit/dist/scss/free/forms/form-range';
@import '~mdb-react-ui-kit/dist/scss/free/tables';
@import '~mdb-react-ui-kit/dist/scss/free/buttons';
@import '~mdb-react-ui-kit/dist/scss/free/deprecated';
@import '~mdb-react-ui-kit/dist/scss/free/dropdown';
@import '~mdb-react-ui-kit/dist/scss/free/button-group';
@import '~mdb-react-ui-kit/dist/scss/free/nav';
@import '~mdb-react-ui-kit/dist/scss/free/navbar';
@import '~mdb-react-ui-kit/dist/scss/free/card';
@import '~mdb-react-ui-kit/dist/scss/free/breadcrumb';
@import '~mdb-react-ui-kit/dist/scss/free/pagination';
@import '~mdb-react-ui-kit/dist/scss/free/badge';
@import '~mdb-react-ui-kit/dist/scss/free/alert';
@import '~mdb-react-ui-kit/dist/scss/free/progress';
@import '~mdb-react-ui-kit/dist/scss/free/list-group';
@import '~mdb-react-ui-kit/dist/scss/free/close';
@import '~mdb-react-ui-kit/dist/scss/free/modal';
@import '~mdb-react-ui-kit/dist/scss/free/toasts';
@import '~mdb-react-ui-kit/dist/scss/free/tooltip';
@import '~mdb-react-ui-kit/dist/scss/free/popover';
@import '~mdb-react-ui-kit/dist/scss/free/scrollspy';
@import '~mdb-react-ui-kit/dist/scss/free/ripple';
@import '~mdb-react-ui-kit/dist/scss/free/range';
@import '~mdb-react-ui-kit/dist/scss/free/accordion';
// @import '~mdb-react-ui-kit/dist/scss/free/carousel';

// @import "~mdb-react-ui-kit/dist/scss/mdb.free";

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';

html {
    height: 100%;
    font-size: 16px;
}

body {
    margin: 0;
    min-width: 320px;
    height: 100%;
    overflow: auto;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[lang|="zh"] body {
    font-family: -apple-system, BlinkMacSystemFont, "Microsoft Yahei", "SimHei", "PingFang SC", "STHeiti", sans-serif;
}

[lang="zh-TW"] body {
    font-family: -apple-system, BlinkMacSystemFont, "Microsoft JhengHei", "SimHei", "PingFang TC", sans-serif;
}

[lang="zh-HK"] body {
    font-family: -apple-system, BlinkMacSystemFont, "Microsoft JhengHei", "SimHei", "PingFang HK", sans-serif;
}

.w-1 {
    width: 1px;
}

form {
    margin: 0;
}

#root {
    height: 100%;
}

.invalid-feedback,
.valid-feedback {
    font-size: 12px;
}

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .form-control:invalid,
.form-control.is-invalid {
    margin-bottom: 0;
}

.h100 {
    height: 100%;
}

.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;

    .mask {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1001;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .75)
    }

    .current {
        position: relative;
        left: 1.5rem;
        top: 1.5rem;
        z-index: 1003;
        width: calc(100% - 3rem);
        height: calc(100% - 3rem);
        object-fit: contain;
        user-select: none;
        pointer-events: none;
    }

    .list {
        position: absolute;
        bottom: 1.5rem;
        left: 1.5rem;
        z-index: 1002;
        width: calc(100% - 3rem);
        height: 82px;
        height: min(82px, calc(10vh + 2px));
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        column-gap: .5rem;
        overflow: auto;

        li {
            border: 1px solid transparent;
            cursor: pointer;

            &.active {
                border-color: $primary;
            }
        }

        img {
            object-fit: contain;
            height: 80px;
            height: min(80px, 10vh);
            width: 80px;
            width: min(80px, 10vh);
        }

        &+.current {
            height: calc(100% - 4.5rem - 82px);
            height: calc(100% - 4.5rem - min(80px, 10vh) - 2px);
        }
    }
}

.form-input {
    display: flex;
    align-items: stretch;

    select.input-group-text {
        width: auto;
        padding-right: $input-padding-right !important;

        &:not(:last-child) {
            border-right: 1px solid $input-group-addon-border-color;
        }
    }

    .input-group-text {
        border-top-color: transparent;
        border-bottom-color: transparent;

        &:not(.form-control + .input-group-text) {
            border-left: 0;
        }

        &:not(:first-child) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &:not(:last-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:last-child {
            border-right: 0;
        }
    }
}

.form-outline select.form-control:not([multiple]) {
    padding-right: $input-padding-right * 2;

    &+.fa {
        position: absolute;
        top: 50%;
        right: $input-padding-right;
        transform: translate(50%, -50%);
        pointer-events: none;
    }
}

#modal-resource {
    --mdb-modal-zindex: 100000;
}

.resource-picker {
    border: 0;
    padding: 0;
    background: none;
    position: relative;
    width: 6.25rem;
    height: 6.25rem;
    display: flex;
    flex-direction: column;

    .btns {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        color: #fff;
        background: rgba(0, 0, 0, 0.3);
    }

    &:hover .btns {
        display: flex;
    }

    button {
        flex: 1 0 auto;
        border: 0;
        padding: 0;
        background: none;
        color: inherit;
        line-height: 1.6875;

        &:hover,
        &:focus {
            background: rgba(0, 0, 0, 0.3);
        }
    }

    img {
        width: 100%;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        border: 1px solid #d5d5d5;
    }
}

.toast {
    position: fixed;
    display: block !important;
    background: rgba(0, 0, 0, 0.8);
    left: 50%;
    z-index: 1000;
    width: auto;
    max-width: 80vw;
    padding: .25rem .5rem;
    opacity: 0;
    transition: opacity .3s;

    .icon {
        display: block;
        margin-bottom: 0.2rem;
        font-size: 300%;
        text-align: center;
    }

    &.show {
        opacity: 1;
    }

    &.top {
        top: 20%;
        transform: translate(-50%, 0);
    }

    &.bottom {
        bottom: 20%;
        transform: translate(-50%, 0);
    }

    &.center {
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.login-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    background-color: var(--mdb-gray);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    .logo {
        position: absolute;
        left: 50%;
        bottom: 2rem;
        transform: translate(-50%, 0);
    }

    .card {
        flex: 0 0 calc(20rem - var(--mdb-gutter-x, .75rem));
        max-width: calc(20rem - var(--mdb-gutter-x, .75rem));
    }

    .captcha {
        position: absolute;
        right: $input-padding-right;
        top: calc(#{$input-padding-top} + 1px);
        z-index: 2;
        width: 3.75rem;
        height: 1.5rem;
        cursor: pointer;
        transition: transform .3s;

        &:hover {
            transform: scale(1.25);
        }

        &+.form-outline input {
            padding-right: $input-padding-right * 2 + 3.75;
        }
    }

    .quick-search {
        position: absolute;
        bottom: .5rem;
        right: .5rem;
        background: none;
        border: none;
        color: #fff;
        line-height: 1;
        padding: .75em 1em;

        &:hover,
        &:focus {
            color: $primary;
        }
    }
}

.header {
    height: 3rem;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    grid-template-columns: 1fr auto 1fr;
    background-color: #fff;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .05);

    .tools {
        display: flex;

        .dropdown-toggle::after {
            display: none;
        }

        .btn {
            padding: 1em;
            font-size: 1rem;
            line-height: 1;
            background: none;
        }

        &:last-child {
            .btn {
                padding: 1em .5em;
            }

            &>.btn:last-child,
            &>:last-child .btn {
                padding-right: 1em;
            }
        }
    }

    .logo {
        width: 3rem;
        object-fit: contain;
        margin: 0 calc(var(--mdb-gutter-x, .75rem) + #{$card-spacer-x}) 0 0;
    }

    .breadcrumb {
        margin: 0;
        align-items: center;
        height: 100%;
    }
}

.nav-container {
    height: calc(100% - 3rem);
    width: 20rem;
    position: absolute;
    left: -20rem;
    top: 3rem;
    z-index: 20;
    box-shadow: 0 15px 15px 0 rgba(0, 0, 0, .05);
    transition: left .6s;

    .mask {
        width: 100vw;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -2;
        display: none;
    }

    &.active {
        left: 0;

        .mask {
            display: block;
        }
    }

    .nav {
        width: 100%;
        height: 100%;
        background-color: #fff;
    }

    .nav-title {
        width: 100%;
        padding: .5rem var(--mdb-gutter-x, .75rem);
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: space-between;

        .btn {
            padding: 0;
            font-size: 150%;
            line-height: 1;
            background: none;
            transition: transform .3s;

            &:hover {
                transform: rotate(180deg);
            }
        }
    }

    .nav-item {
        width: 100%;
        margin: 0;

        .nav {
            position: absolute;
            top: 0;
            left: -20rem;
            transition: left .3s;
            width: 20rem;
            box-shadow: 3px 0 5px rgba(0, 0, 0, .05) inset, 0 3px 3px rgba(0, 0, 0, .1);

            &.active {
                left: 0;
            }
        }
    }

    .nav-link {
        position: relative;
        padding: .5rem var(--mdb-gutter-x, .75rem);
        text-align: left;
        cursor: pointer;

        .tip {
            font-size: .875rem;
            line-height: 1;
            display: inline-block;
            margin-left: .5em;
        }
    }
}

.main-content {
    height: 100%;
    background-color: var(--mdb-gray-100);
    padding: calc(3rem + var(--mdb-gutter-y, 0.75rem)) var(--mdb-gutter-x, 0.75rem) var(--mdb-gutter-y, 0.75rem);
    position: relative;
    overflow: auto;

    &>.row {
        margin-right: 0;
    }
}

.grid {
    display: flex;
    flex-direction: column;
    height: 100%;

    .grid-content {
        flex: 1 1 0;

        .table-responsive {
            height: 100%;
        }
    }

    td.empty {
        text-align: center;
        padding: 6.25rem 0;
    }

    img:not([width][height]) {
        width: 6.25rem;
        height: 6.25rem;
        object-fit: cover;
    }

    .filter-toggle {
        order: -1;
        background: #fff;
        color: $form-label-color;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        box-shadow: none;
        border-radius: $card-border-radius $card-border-radius 0 0;
        border-bottom: 1px solid $table-border-color;
        padding: var(--mdb-gutter-y, 0.75rem) var(--mdb-gutter-x, 0.75rem);
    }

    .filter {
        background: #fff;
        padding: 0 var(--mdb-gutter-x, 0.75rem);
        border-radius: 0 0 $card-border-radius $card-border-radius;

        form {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            padding: var(--mdb-gutter-y, 0.75rem) 0;
            gap: .75rem;

            &>* {
                flex: 0 0 100%;
            }
        }

        &:not(.show)+.filter-toggle {
            border-radius: $card-border-radius;
        }
    }

    th .btn {
        padding: 0;
        font-size: inherit;

        &>svg {
            margin: 0 0 0 .5em;
        }
    }

    tr> :first-child {
        position: sticky;
        left: 0;
        width: 1px;
        white-space: nowrap;
        background: #fff;
    }

    .check {
        input {
            margin-right: 0;
        }

        &+* {
            position: sticky;
            left: $table-cell-padding-x * 2 + $form-check-input-width-md;
            width: 1px;
            white-space: nowrap;
        }
    }

    .action {
        width: 1px;
        white-space: nowrap;
    }

    .w-1 {
        width: 1px;
    }

    .pager {
        --mdb-card-spacer-y: 0.75rem;
        --mdb-card-spacer-x: 0.75rem;

        .card-body:empty {
            display: none;
        }
    }
}

.edit {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    textarea.form-control {
        height: 10rem;
        resize: none;
    }

    .accordion {
        flex: 1 1 auto;
        --mdb-accordion-body-padding-x: 1rem;

        .accordion-body {
            display: flex;
            flex-direction: column;
            row-gap: 1rem;

            &>label {
                display: flex;
                align-items: flex-start;
                column-gap: 1ch;

                &>.label {
                    display: none;
                    line-height: 1.2;
                    min-height: 2.1825rem;
                    flex: 0 0 9.735rem;
                    align-items: center;
                }
            }
        }
    }

    .tools {
        position: sticky;
        bottom: 0;

        .card-body {
            display: flex;
            gap: 1rem;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: space-between;
            --mdb-card-spacer-y: 1rem;
            --mdb-card-spacer-x: 1rem;
        }

        .btns {
            display: flex;
            gap: 1rem;
        }
    }
}

.dashboard {
    height: calc(100% - $btn-padding-top - $btn-padding-bottom - $pagination-font-size - 1.25rem);
    overflow: auto;
    position: relative;

    &+.pagination {
        width: 100%;
        background: #fff;
        justify-content: center;
        padding: .25rem .5rem;
        border-radius: .5rem;
        margin-top: .75rem;

        button {
            --mdb-btn-line-height: 1;
        }
    }

    .inventory {

        &:not(:first-child) {
            margin-top: .75rem;
        }

        .card-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .info {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                gap: .5rem .75rem;

                .label::after {
                    content: ": ";
                }
            }

            .btn {
                padding: .5rem;
                margin: -.5rem;
                margin-left: .5rem;
                font-size: 1.25rem;
                line-height: 1.128rem;
            }

            .export {
                .fa-spin {
                    display: none;
                }

                &:disabled {
                    .fa:not(.fa-spin) {
                        display: none;
                    }

                    .fa-spin {
                        display: block;
                    }
                }
            }

            .toggler {
                font-size: 1.5rem;
            }
        }

        .card-body {
            display: grid;
            grid-template-columns: 1fr;
            margin: 0;
            padding: 0 1.25rem;
            list-style-position: inside;
            overflow: hidden;
            transition: height .3s, padding .3s;
            position: relative;

            .btns {
                position: absolute;
                right: 0;
                bottom: 0;
                list-style: none;

                button {
                    border: none;
                    background: none;
                    padding: 1rem 0.5rem 0.5rem 1rem;
                }
            }
        }

        dl.card-body {
            display: grid;
            gap: .25rem;
            grid-template-columns: 1fr 1fr;
            text-align: center;

            dd {
                margin: 0;
            }

            &:not(:has(dd)) {
                grid-template-columns: 1fr;
            }

            .dropdown-menu {
                padding: .5rem
            }
        }

        .items {
            padding: 0;
        }
    }

    .loading,
    .empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: .75rem;
        row-gap: .5rem;

        .fa,
        .far {
            font-size: 2.5rem;
        }
    }
}

.stock-grid {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    th {
        white-space: nowrap;
        min-width: 10ch;

        &:first-child {
            --mdb-table-accent-bg: #fff;
            position: sticky;
            left: 0;
            z-index: 2;
            box-shadow: none;
            min-width: 0;
            background: var(--mdb-table-accent-bg);
        }
    }

    td {
        word-break: break-all;
        padding-inline: calc(.25rem + 2.035ch);
    }

    tbody>tr:nth-of-type(odd)>* {
        --mdb-table-accent-bg: #fafafa;
    }

    th,
    td {
        position: relative;

        .badge {
            --mdb-bg-opacity: 0.5;
            --mdb-badge-padding-x: .75ch;
            display: inline-block;
            margin-left: .25rem;
            pointer-events: none;
        }

        button,
        a {
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 2;
            padding: .5rem .125rem .125rem .5rem !important;
            border: none;
            background: none;
            box-shadow: none;
            opacity: 0;
            transition: opacity .3s;
            color: #333;

            &:hover,
            &:focus {
                color: $primary;
            }

            &:not(.history)+button {
                right: 2rem;
            }

            &.banned {
                position: static;
                opacity: .75;
                padding: 0 !important;
                font-size: 1.2rem;
                line-height: 1;
            }

            &.history {
                padding: 0.5rem 0.5rem 0.125rem 0.125rem !important;
                left: 0;
                right: auto!important;
            }
        }

        &:hover button,
        &:hover a {
            opacity: 1
        }
    }
}

.inventory-settings {
    background-color: #fff;

    caption {
        background-color: #fff;
        border-bottom: 1px solid var(--mdb-table-border-color);
        color: var(--mdb-table-color);
        font-weight: bold;
        text-align: center;
        position: relative;

        button {
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    button {
        background: none;
        border: none;
        height: 100%;
        padding: 0 .75rem;
    }
}

th:has(.thead-tip) {
    white-space: nowrap;

    .thead-tip {
        display: inline-block;
        padding: 0 .5rem;
        margin-right: -1rem;
        background: none!important;
        vertical-align: baseline;
        cursor: help;
        color: #aaa;
    }
}

.touchevents {

    .resource-picker {
        height: 8rem;

        .btns {
            position: static;
            height: auto;
            display: flex;
            background: none;
        }

        button {
            color: #666;
            border: 1px solid #d5d5d5;
            border-top: none;

            &:not(:first-child) {
                border-left: none;
            }

            &:hover,
            &:focus {
                background: none;
                color: $primary;
            }
        }
    }

    .stock-grid td:hover button {
        opacity: 1
    }
}

@include media-breakpoint-up(sm) {

    .edit {
        .accordion .accordion-body>label {
            .form-label {
                visibility: hidden;
            }

            .form-outline .form-notch-middle {
                border-top-color: $form-notch-div-border-color;
            }

            .form-control:focus~.form-notch .form-notch-middle {
                border-top-color: $input-focus-border-color;
                box-shadow: 0 1px 0 0 $input-focus-border-color, 0 -1px 0 0 $input-focus-border-color;
            }

            &>.label {
                display: flex;
            }
        }

        &.was-validated .form-outline .form-control:valid:focus~.form-notch .form-notch-middle,
        &.was-validated .form-outline .form-control:valid.active~.form-notch .form-notch-middle,
        .form-outline .form-control.is-valid:focus~.form-notch .form-notch-middle,
        .form-outline .form-control.is-valid.active~.form-notch .form-notch-middle {
            border-top-color: $success;
        }

        &.was-validated .form-outline .form-control:valid:focus~.form-notch .form-notch-middle,
        .form-outline .form-control.is-valid:focus~.form-notch .form-notch-middle {
            box-shadow: 0 1px 0 0 $success, 0 -1px 0 0 $success;
        }

        &.was-validated .form-outline .form-control:invalid:focus~.form-notch .form-notch-middle,
        &.was-validated .form-outline .form-control:invalid.active~.form-notch .form-notch-middle,
        .form-outline .form-control.is-invalid:focus~.form-notch .form-notch-middle,
        .form-outline .form-control.is-invalid.active~.form-notch .form-notch-middle {
            border-top-color: $danger;
        }

        &.was-validated .form-outline .form-control:invalid:focus~.form-notch .form-notch-middle,
        .form-outline .form-control.is-invalid:focus~.form-notch .form-notch-middle {
            box-shadow: 0 1px 0 0 $danger, 0 -1px 0 0 $danger;
        }
    }

    .dashboard .inventory .card-body {
        grid-template-columns: repeat(2, 1fr);
    }
}

@include media-breakpoint-up(md) {

    .login-panel .logo {
        position: absolute;
        left: 2rem;
        bottom: 2rem;
        transform: none;
    }

    .header {
        grid-template-columns: auto 1fr;
    }

    .nav-container {
        left: 0;
        width: 3rem;

        .nav-item .nav {
            z-index: -1;

            &.active {
                left: 100%;
            }
        }

        &>.nav>.nav-item>.nav-link {
            text-align: center;
            padding: .5rem 0;

            .tip {
                display: block;
                position: absolute;
                left: calc(100% + .5em);
                top: calc(50% - 1em);
                z-index: 5;
                pointer-events: none;
                opacity: 0;
                transition: opacity .3s;
                white-space: nowrap;
                background-color: #fff;
                font-size: .75rem;
                padding: .5em 1em;
                border-radius: .25em;
                box-shadow: 0 0 .5em rgba(0, 0, 0, .1);

                &::before {
                    content: " ";
                    display: block;
                    width: 0;
                    height: 0;
                    border: .25em solid transparent;
                    border-right-color: #fff;
                    position: absolute;
                    right: 100%;
                    top: calc(50% - .25em);
                }
            }

            &:hover .tip {
                opacity: 1;
            }
        }
    }

    .nav-container+.main-content {
        padding-left: calc(3rem + var(--mdb-gutter-x, .75rem));
    }

    .grid {
        .filter {
            display: block !important;
            height: auto !important;
            border-radius: $card-border-radius;

            form {
                display: inline-flex;
                flex-direction: row;

                .form-outline {
                    width: auto;
                }

                &>* {
                    flex: 0 0 auto;
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .edit {
        flex-direction: row;
        align-items: flex-start;

        .tools {
            flex: 0 0 15rem;
            top: 0;
            bottom: auto;

            .card-body {
                flex-direction: column;
                align-items: stretch;
            }

            .btns {
                flex-direction: column-reverse;
            }
        }
    }

    .dashboard .inventory {

        .card-header .info {
            grid-template-columns: repeat(2, 1fr);
        }

        .card-body {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

@media screen and (min-width: 1600px) {
    .dashboard .inventory {

        .card-header .info {
            grid-template-columns: repeat(4, 1fr);
        }

        .card-body {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}

@media screen and (max-height: 520px) {
    .login-panel .logo {
        bottom: 1rem;
    }
}

@media print {
    .hidden-print, .header, .nav-container {
        display: none!important;
    }

    .visible-print {
        display: block!important;
    }

    .main-content {
        padding: var(--mdb-gutter-y, 0.75rem) var(--mdb-gutter-x, 0.75rem)!important;
        height: auto;
    }
}